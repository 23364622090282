import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import english from './en.json';
import bahasa from './id.json';
import espana from './es.json';
import francais from './fr.json';
import filipino from './fi.json';
import zhongwen from './zh.json';

const resources = {
  en: {
    translation: english
  },
  zh: {
    translation: zhongwen
  },
  id: {
    translation: bahasa
  },
  es: {
    translation: espana
  },
  fr: {
    translation: francais
  },
  fi: {
    translation: filipino
  }
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
  });

export default i18n;